/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import GeneralLayout from '../components/GeneralLayout';
import AboutBackground from '../components/AboutBackground';

function About() {
  const sanityAboutQuery = useStaticQuery(graphql`
    query {
      allSanityAboutInfo {
        nodes {
          praudBio {
            children {
              text
            }
          }
          headshot2 {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          headshot1 {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          headerImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          bio2 {
            children {
              text
            }
          }
          bio1 {
            children {
              text
            }
          }
        }
      }
      allSanityAwardPastNews(sort: {order: DESC, fields: date}) {
        nodes {
          category
          date
          title
        }
      }
    }
  `);

  const aboutData = sanityAboutQuery.allSanityAboutInfo.nodes[0];
  const awards = sanityAboutQuery.allSanityAwardPastNews.nodes.filter((node) => node.category === 'A');

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <>
      <AboutBackground />
      <GeneralLayout>
        <div className="about-container">
          <div className="praud-bio-container">
            {aboutData.praudBio.map((paragraph, i) => {
              if (i === 0) {
                return (
                  <p key={i}>
                    {paragraph.children[0].text}
                  </p>
                );
              }
              return (
                <p key={i}>
                  {paragraph.children[0].text}
                </p>
              );
            })}
          </div>
          <div className="partner-bios">
            <div className="partner-bio-container">
              <GatsbyImage
                className="partner-headshot"
                image={aboutData.headshot1.asset.gatsbyImageData}
                alt="Partner 1 Headshot"
              />
              <div className="partner-bio-text-container">
                {aboutData.bio1
                  ? aboutData.bio1.map((paragraph, i) => {
                    if (i === 0) {
                      return (
                        <p key={i}>
                          {paragraph.children[0].text}
                        </p>
                      );
                    }
                    return (
                      <p key={i}>
                        {paragraph.children[0].text}
                      </p>
                    );
                  })
                  : ''}
              </div>
            </div>
            <div className="partner-bio-container">
              <GatsbyImage
                className="partner-headshot"
                image={aboutData.headshot2.asset.gatsbyImageData}
                alt="Partner 2 Headshot"
              />
              <div className="partner-bio-text-container">
                {aboutData.bio2
                  ? aboutData.bio2.map((paragraph, i) => {
                    if (i === 0) {
                      return (
                        <p key={i}>
                          {paragraph.children[0].text}
                        </p>
                      );
                    }
                    return (
                      <p key={i}>
                        {paragraph.children[0].text}
                      </p>
                    );
                  })
                  : ''}
              </div>
            </div>
          </div>
          <div className="awards">
            <p className="label">Awards</p>
            {awards.map((award) => (
              <div className="award">
                <p className="date">{award.date}</p>
                <p className="award-title">{award.title}</p>
              </div>
            ))}
          </div>
        </div>
      </GeneralLayout>
    </>
  );
}

export default About;
